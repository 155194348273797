<template>
  <div class="px-2 mt-1">
    <header-slot>
      <template #actions>
        <div class="d-flex justify-content-end">
          <b-button
            class="mr-1 btn-back"
            variant="primary"
            :to="{ name: $route.meta.employeeClaimsDashboardRoute }"
          >
            <feather-icon icon="ArrowLeftIcon" size="15" />
            <span> Back to Dashboard</span>
          </b-button>
          <b-button
            class="btn-black"
            v-if="!allClaims"
            variant="success"
            @click="openModalCreateClaimRequest"
          >
            <feather-icon icon="PlusIcon" size="15" />
            <span> New Claim</span>
          </b-button>
        </div>
        <!-- <div>
          <b-button
            v-if="!allClaims"
            class="mr-1"
            variant="success"
            @click="openModalCreateClaimRequest"
          >
            <feather-icon icon="PlusIcon" size="15" />
            <span> New Request</span>
          </b-button>
        </div> -->
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.meta.claimsAllTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        All Claims
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.claimsListTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        My Claims
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <ModalCreateClaimRequest
      v-if="showModalCreateClaimRequest"
      @close="closeModalCreateClaimRequest"
    />
  </div>
</template>
  
  <script>
import ModalCreateClaimRequest from "@/views/commons/employee-claims/components/modal/ModalCreateEmployeeClaim.vue";
export default {

  components: {
    ModalCreateClaimRequest,
  },
  data() {
    return {
      showModalCreateClaimRequest: false,
    };
  },
  computed: {
    // All claims for Management
    allClaims() {
      return this.$route.meta.allClaims ?? 0;
    },
  },
  methods: {
    openModalCreateClaimRequest() {
      this.showModalCreateClaimRequest = true;
    },
    closeModalCreateClaimRequest() {
      this.showModalCreateClaimRequest = false;
    },
  },

};
</script>
  